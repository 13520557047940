import { useState } from "react";
import { Link } from "react-router-dom";

function Innernavbar(){
const[show_hide, set_show_hide] = useState("");
  const menu_show_hide = ()=>{
    console.log("Mobile button click");
    set_show_hide("n_mobile_show");
  }
  const gray_body_click = ()=>{
    set_show_hide("");
  }
return(
    <>
   
        <div className="navbar-area inner_nav White_Nav">
     
     <div className="mobile-nav">
       <a href="/" className="logo">
         <img
           className="img-fluid"
           src="/assets/images/whiteReliep.svg"
           alt=""
         />
       </a>
      
     </div>

    
     <div className="main-nav">
        <div className="header-top text-end">
            <div className="container">
                     <ul>
                     <li onClick={()=>{menu_show_hide()}} className="mobile_only"><i class="fa fa-bars" aria-hidden="true" style={{"fontSize": "22px"}}></i></li>
                     <li className="mobile_only">
                            <a className="navbar-brand" href="/">
                                <img
                                    className="img-fluid"
                                    src="/assets/images/whiteReliep.png"
                                    alt=""
                                />
                            </a>
                            </li>
                   <li><a href="#"><img src="/assets/images/smartphone-call.png" alt="" />App</a></li>
                   <li><a href="#"><img src="/assets/images/bussinesIcon.svg" alt="" />Businesses</a></li>
               </ul>
            </div>
               
       </div>
       <div className={`header_bottom ${show_hide}`}>
       <div className="container">
        
         <nav className="navbar navbar-expand-md navbar-light pb-1">
           <a className="navbar-brand" href="/">
             <img
               className="img-fluid"
               src="/assets/images/Reliep.svg"
               alt=""
             />
           </a>
           <div
             className="collapse navbar-collapse mean-menu"
             id="navbarSupportedContent"
           >
             <ul className="navbar-nav ms-auto">
               <li className="nav-item">
                 <Link to="/" className="nav-link">Home</Link>
                
               </li>
               <li className="nav-item">
                 <a href="/about-us" className="nav-link">About Us</a>
               </li>
               <li className="nav-item">
                 <Link to="/services" className="nav-link">Service</Link>
               </li>
               {/* <li className="nav-item">
                 <a href="#" className="nav-link">Trains</a>
               </li>
               <li className="nav-item">
                 <a href="#" className="nav-link">Flights</a>
               </li>
               <li className="nav-item">
                 <a href="#" className="nav-link">Hotels</a>
               </li> */}
               <li className="nav-item">
                 <a href="#" className="nav-link">Offers</a>
               </li>
               <li className="nav-item">
                 <a href="#" className="nav-link"><img src="/assets/images/online-shopping_grey.svg" alt="" /></a>
               </li>
               <li className="nav-item">
                 <Link to="/profile" className="nav-link"><img src="/assets/images/user_grey.png" alt="" /></Link>
               </li>
             </ul>
           </div>
         </nav>
       </div>
       </div>

     </div>
   </div>


   <div className={`body_gray ${show_hide}`} onClick={()=>{gray_body_click()}}></div>
    </>
)
}
export default Innernavbar;