import { useEffect, useState } from 'react';
import apiService from '../../apiService';
import { toast } from 'react-toastify';

function Editprofile(){

    const [u_data, set_u_data] = useState([]);
    const [ProfileImage,setProfileImage] = useState("assets/images/Ellipse_user.svg");
    const [p_image,setp_image] = useState("");
    const [user_data, set_user_data] = useState([]);

    useEffect(()=>{
        get_user_profile_data()
        
        },[])

        const get_user_profile_data = async()=>{
            const get_data = await apiService.get_user_full_details()
            if(get_data.status==200){
            // set_user_data(get_data.data.data);
            const user_data = get_data.data.data;
            u_data["pre_name"] = user_data["pre_name"];
            u_data["first_name"] = user_data["first_name"];
            u_data["last_name"] = user_data["last_name"];
            u_data["phone"] = user_data["phone"];
            u_data["email"] = user_data["email"];
            u_data["dob"] = user_data["dob"];
            u_data["marital_status"] = user_data["marital_status"];
            u_data["pincode"] = user_data["pincode"];
            u_data["city"] = user_data["city"];
            u_data["landmark"] = user_data["landmark"];
            u_data["nationality"] = user_data["nationality"];
            u_data["country"] = user_data["country"];
            u_data["landline_number"] = user_data["landline_number"];
            u_data["address"] = user_data["address"];
            u_data["profile_pic"] = user_data["profile_pic"];
            if(user_data["profile_pic"]!=""){
                setProfileImage(user_data["profile_pic"])
            }
            set_u_data(u_data);
            }else{
                toast.error("Something wrong.")
            }
        }
        
        
        const setdoc_data_click = (val) => {
            u_data["country"] = "India";
            set_u_data({ ...u_data, ...val });
          };
        

        
        const profile_image_upload = (e)=>{
            const file = e.target.files[0];
            setp_image(file);
            if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                setProfileImage(reader.result);
              };
              reader.readAsDataURL(file);
            }
        }
        
        
        const submit_form_data = async(e)=>{
            e.preventDefault();
            const formData = new FormData();
            formData.append("data",JSON.stringify(u_data));
            formData.append('profile_image', p_image);
            formData.append('p_image', u_data["profile_pic"]);
             console.log(u_data);
            const res =  await apiService.user_details_update(formData);
            if(res.status==200){
                if(res.data.status==1){
                toast.success(res.data.msg);
                get_user_profile_data();
                }
            }
        }
    return(
        <>
         <div className="col-md-8 ">
                        <div className="profile_content">
                            <div className="profile_contentTop">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg" onChange={(e)=>{profile_image_upload(e)}}
                                        />
                                        <label htmlFor="imageUpload"></label>
                                    </div>
                                    <div className="avatar-preview">
                                        <div
                                            id="imagePreview"
                                            style={{
                                                backgroundImage: `url(${ProfileImage})`,
                                            }}
                                        ></div>
                                    </div>
                                    <h5>{u_data["pre_name"]}. {u_data["first_name"]} {u_data["last_name"]}</h5>
                                </div>
                            </div>
                            <div className="peronalInfoarea">
                                <form action="" className="PsInfoform" onSubmit={(e)=>{submit_form_data(e)}}>
                                    <div className="PsInfoformstep">
                                        <h4>Personal</h4>
                                        <ul>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Mr"
                                                        id="flexCheckDefault2"
                                                        checked={u_data["pre_name"]=="Mr"}
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mr.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Ms"
                                                        id="flexCheckDefault2"
                                                        checked={u_data["pre_name"]=="Ms"}
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Ms.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Mrs"
                                                        id="flexCheckDefault2"
                                                        checked={u_data["pre_name"]=="Mrs"}
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mrs.
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First & Middle Name"
                                                    aria-label="First & Middle Name"
                                                    name='first_name'
                                                    value={u_data["first_name"]}
                                                    required onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last name"
                                                    aria-label="Last Name"
                                                    required
                                                    name='last_name'
                                                    value={u_data["last_name"]}
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group veriified">
                                                    <input
                                                        type="number"
                                                        id="mobile_code"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        name="phone"
                                                        required
                                                        value={u_data["phone"]}
                                                        onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email ID"
                                                    aria-label="Email ID"
                                                    required
                                                    name='email'
                                                    value={u_data["email"]}
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" required name='dob' value={u_data["dob"]} onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}} />
                                            </div>
                                        
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='marital_status' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Marital Status</option>
                                                    <option value="Married" selected={u_data["marital_status"]=="Married"}>Married</option>
                                                    <option value="Unmarried" selected={u_data["marital_status"]=="Unmarried"}>Unmarried</option>
                                                    <option value="Widow" selected={u_data["marital_status"]=="Widow"}>Widow</option>
                                                    <option value="Other" selected={u_data["marital_status"]=="Other"}>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PsInfoformstep">
                                        <h4>Location</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Pincode / Zip code"
                                                    aria-label="Pincode / Zip code"
                                                    required
                                                    name='pincode'
                                                    value={u_data["pincode"]}
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="City"
                                                    aria-label="Citye" required
                                                    value={u_data["city"]}
                                                    name='city' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Landmark"
                                                    name="landmark"
                                                    required
                                                    value={u_data["landmark"]}
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='nationality' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Nationality</option>
                                                    <option value="Indian" selected={u_data["nationality"]=="Indian"}>Indian</option>
                                                    <option value="Other" selected={u_data["nationality"]=="Other"}>Other</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='country' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Country of residence</option>
                                                    <option value="Afghanistan" selected={u_data["country"]=="Afghanistan"}>Afghanistan</option>
                                                    <option value="Åland Islands" selected={u_data["country"]=="Åland Islands"}>Åland Islands</option>
                                                    <option value="Albania" selected={u_data["country"]=="Albania"}>Albania</option>
                                                    <option value="Algeria" selected={u_data["country"]=="Algeria"}>Algeria</option>
                                                    <option value="Argentina" selected={u_data["country"]=="Argentina"}>Argentina</option>
                                                    <option value="Armenia" selected={u_data["country"]=="Armenia"}>Armenia</option>
                                                    <option value="Aruba" selected={u_data["country"]=="Aruba"}>Aruba</option>
                                                    <option value="Australia" selected={u_data["country"]=="Australia"}>Australia</option>
                                                    <option value="Austria" selected={u_data["country"]=="Austria"}>Austria</option>
                                                    <option value="Azerbaijan" selected={u_data["country"]=="Azerbaijan"}>Azerbaijan</option>
                                                    <option value="Bahamas" selected={u_data["country"]=="Bahamas"}>Bahamas</option>
                                                    <option value="Bahrain" selected={u_data["country"]=="Bahamas"}>Bahrain</option>
                                                    <option value="Bangladesh" selected={u_data["country"]=="Bangladesh"}>Bangladesh</option>
                                                    <option value="Barbados" selected={u_data["country"]=="Barbados"}>Barbados</option>
                                                    <option value="Belarus" selected={u_data["country"]=="Belarus"}>Belarus</option>
                                                    <option value="Belgium" selected={u_data["country"]=="Belgium"}>Belgium</option>
                                                    <option value="Belize" selected={u_data["country"]=="Belize"}>Belize</option>
                                                    
                                                    <option value="Bhutan" selected={u_data["country"]=="Bhutan"}>Bhutan</option>
                                                    <option value="Bolivia" selected={u_data["country"]=="Bolivia"}>Bolivia</option>
                                                    <option value="Brazil" selected={u_data["country"]=="Brazil"}>Brazil</option>
                                                    <option value="Bulgaria" selected={u_data["country"]=="Bulgaria"}>Bulgaria</option>
                                                    <option value="Burundi" selected={u_data["country"]=="Burundi"}>Burundi</option>
                                                    <option value="Cambodia" selected={u_data["country"]=="Cambodia"}>Cambodia</option>
                                                    <option value="Cameroon" selected={u_data["country"]=="Cameroon"}>Cameroon</option>
                                                    <option value="Canada" selected={u_data["country"]=="Canada"}>Canada</option>
                                                    <option value="Chile" selected={u_data["country"]=="Chile"}>Chile</option>
                                                    <option value="China" selected={u_data["country"]=="China"}>China</option>
                                                    <option value="Colombia" selected={u_data["country"]=="Colombia"}>Colombia</option>
                                                    <option value="Comoros" selected={u_data["country"]=="Comoros"}>Comoros</option>
                                                    <option value="Congo" selected={u_data["country"]=="Congo"}>Congo</option>
                                                    <option value="Costa Rica" selected={u_data["country"]=="Costa Rica"}>Costa Rica</option>
                                                    <option value="Croatia" selected={u_data["country"]=="Croatia"}>Croatia</option>
                                                    <option value="Cuba" selected={u_data["country"]=="Cuba"}>Cuba</option>
                                                    <option value="Cyprus" selected={u_data["country"]=="Cyprus"}>Cyprus</option>
                                                    <option value="Czech Republic" selected={u_data["country"]=="Czech Republic"}>Czech Republic</option>
                                                    <option value="Denmark" selected={u_data["country"]=="Denmark"}>Denmark</option>
                                                    <option value="Dominica" selected={u_data["country"]=="Dominica"}>Dominica</option>
                                                    <option value="Egypt" selected={u_data["country"]=="Egypt"}>Egypt</option>
                                                    <option value="Estonia" selected={u_data["country"]=="Estonia"}>Estonia</option>
                                                    <option value="Ethiopia" selected={u_data["country"]=="Ethiopia"}>Ethiopia</option>
                                                    <option value="Fiji" selected={u_data["country"]=="Fiji"}>Fiji</option>
                                                    <option value="Finland" selected={u_data["country"]=="Finland"}>Finland</option>
                                                    <option value="France" selected={u_data["country"]=="France"}>France</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guernsey">Guernsey</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India"  selected={u_data["country"]=="India"}>India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jersey">Jersey</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macao">Macao</option>
                                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russian Federation">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Helena">Saint Helena</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Timor-leste">Timor-leste</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Viet Nam">Viet Nam</option>
                                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Landline Number"
                                                    name="landline_number"
                                                    value={u_data["landline_number"]}
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Address" value={u_data["address"]} required name='address' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="PsInfoformstep">
                                        <h4>friends and family</h4>
                                        <ul>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mr.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Ms.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mrs.
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Frist & Middle Name"
                                                    aria-label="Frist & Middle Name"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last name"
                                                    aria-label="Last Name"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="mobile_code2"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email ID"
                                                    aria-label="Email ID"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" />
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Nationality</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Marital Status</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <button type="submit" className="btn savechanges">
                                        Save Changes
                                    </button>
                                    <p className="privacytext">
                                        By clicking on Save Changes, you agree to the{' '}
                                        <a href="#!">Terms & Conditions</a> &{' '}
                                        <a href="#">Privacy Policy</a> of Reliep Customer
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
        </>
    )
}

export default Editprofile