import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import { useEffect, useState } from "react";
import apiService from "../apiService";
import { toast } from "react-toastify";
import RatingsComponent from "../Components/RatingComponent";
import moment from "moment";
import GoogleAutocomplete from "../Components/GoogleAutocomplete";


import { 
    useJsApiLoader, 
    Autocomplete } from '@react-google-maps/api';
import axios from "axios";
import Map from "../Components/google-location-map/Map";
import MapContainer from "../Components/google-location-map/Distance-map";


function Details() {
  const { slug } = useParams();
  const [product_Details, set_product_details] = useState(null);
  const [location, set_location] = useState("");
  const [savedFavourites, setSavedFavourites] = useState([]);
  const navigate = useNavigate();
  const [review_ratings, set_review_ratings] = useState([]);
  const [review_show, set_review_show] = useState(0);
  const [review_ratings_add, set_review_ratings_add] = useState(0);
  const [review_comments, set_review_comments] = useState("");

  const [enquire_show_hide, setenquire_show_hide] = useState(0);
  const [order_name, setorder_name] = useState("");
  const [order_mobile, setorder_mobile] = useState("");

  const [success, setsuccess] = useState(0);
  const [book_now_show, set_book_now_show] = useState(0);

  const [response, setResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [calculated_price, setcalculated_price] = useState("");

  const [google_map_api, setgoogle_map_api] = useState(process.env.REACT_APP_GOOGLE_MAP_API);

  const [query, setQuery] = useState('');
  const [querydesti, setquerydesti] = useState("");
  const [pickup_auto, setpickup_auto] = useState([]);
  const [desti_auto, setdesti_auto] = useState([]);
  const [pickup_dt, setpickup_dt] = useState(0);
  const [desti_dt, setdesti_dt] = useState(0);
  const [pickup_location, setpickup_location] = useState({ lat: 22.5726, lng: 88.3639 });
  const [destination_location, setdestination_location] = useState({ lat: 22.5726, lng: 88.3639 });
  const [booking_popup_modal,setbooking_popup_modal] = useState(0);

//   const pickup_location = { lat: 22.5726, lng: 88.3639 }; // Kolkata coordinates
//   const destination_location = { lat: 20.9517, lng: 85.0985 }; // Odisha coordinates

  
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAP_API,
    libraries:["places"]
})

  
  useEffect(() => {
    get_data_from_service(slug);
    const p_city = localStorage.getItem("present-city");
    set_location(p_city);

   // fetchDirections();
  }, []);

  const get_duration_distance_data = async (pickup_lo, destination_lo) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;
    const origin = `${pickup_lo.lat},${pickup_lo.lng}`;
    const destination = `${destination_lo.lat},${destination_lo.lng}`;
    const apiUrl = `/maps/api/distancematrix/json?units=metric&origins=${origin}&destinations=${destination}&key=${apiKey}`;

    try {
     // const response = await axios.get(apiUrl);
     const response = await apiService.get_location_matrix_data(origin, destination);
      //console.log(response.data);
      const distance = response.data.rows[0].elements[0].distance.text;
      const duration = response.data.rows[0].elements[0].duration.text;
      const distanceValue = parseFloat(distance);
      const calcu = distanceValue*parseFloat(product_Details.price);
      setcalculated_price(calcu);
      setDistance(distance);
      setDuration(duration);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_data_from_service = async (slug) => {
    const res = await apiService.get_specific_product_data(slug);
    set_product_details(res.data.data);

    get_reviews(slug);
    get_user_wise_favourite();
  };

  const click_submit_review = async () => {
    if (review_ratings_add == 0) {
      toast.error("Please add retings");
    } else if (review_comments == "") {
      toast.error("Please add comment");
    } else {
      var ary = {
        ratings: review_ratings_add,
        comments: review_comments,
        slug: slug,
      };

      const res = await apiService.submit_review_data(ary);

      if (res.data.status == 1) {
        toast.success(res.data.msg);
        close_review_popup();
      }
    }
  };

  const add_favourite = async (data) => {
    const is_active_user = localStorage.getItem("user-token");
    // console.log(is_active_user);
    if (is_active_user == null) {
      localStorage.setItem("redirect_to", "/details/" + slug);
      toast.error("Please login first");
      navigate("/login");
    } else {
      const res_data = await apiService.save_to_favourite(
        data.services_id,
        is_active_user
      );
      // console.log(res_data.data);
      if (res_data.data.status == 1) {
        toast.success(res_data.data.msg);
      } else {
        toast.error(res_data.data.msg);
      }

      get_user_wise_favourite();
    }
  };

  const get_user_wise_favourite = async () => {
    const is_active_user = localStorage.getItem("user-token");
    if (is_active_user != null) {
      const get_favourite_saved =
        await apiService.get_favourite_saved_services();
      setSavedFavourites(get_favourite_saved.data.data);
    }
  };

  const add_review = () => {
    const is_active_user = localStorage.getItem("user-token");
    // console.log(is_active_user);
    if (is_active_user == null) {
      localStorage.setItem("redirect_to", "/details/" + slug);
      toast.error("Please login first");
      navigate("/login");
    } else {
      set_review_show(1);
    }
  };

  const get_reviews = async (slug) => {
    const res = await apiService.get_reviews(slug);
    // console.log(res.data.data);
    set_review_ratings(res.data.data);
  };

  const close_review_popup = () => {
    set_review_show(0);
  };

  const service_booking = () => {
    setenquire_show_hide(1);
  };

  const close_enquire_popup = () => {
    setenquire_show_hide(0);
    setsuccess(0);
  };

  const send_booking = async () => {
    var namePattern = /^[A-Za-z\s]+$/;
    var mobilePattern = /^[6789]\d{9}$/;
    if (order_name == "") {
      toast.error("Name field cannot be empty");
    } else if (!namePattern.test(order_name)) {
      toast.error("Please enter valid email");
    } else if (order_mobile == "") {
      toast.error("Mobile number cannot be empty");
    } else if (!mobilePattern.test(order_mobile)) {
      toast.error("Please enter valid mobile number");
    } else {
      //console.log(product_Details);

      var data = {
        name: order_name,
        mobile: order_mobile,
        service_id: product_Details["services_id"],
        price: product_Details["price"],
      };

      const dt = await apiService.enquiry_order_add(data);
      const new_data = dt.data.status;

      if (new_data == 1) {
        setsuccess(1);
      }
    }
  };

  const book_now = () => {
    const is_active_user = localStorage.getItem("user-token");
    // console.log(is_active_user);
    if (is_active_user == null) {
      localStorage.setItem("redirect_to", "/details/" + slug);
      toast.error("Please login first");
      navigate("/login");
    } else {
      set_book_now_show(1);
      
    }
  };

  const close_book_popup = () => {
    set_book_now_show(0);
  };


  const handleChangepickup = async(event)=>{
    setpickup_dt(1);
    const ddt = event.target.value;
    setQuery(ddt);
    const pickup_locatio = await handleChange(event.target.value);
    setpickup_auto(pickup_locatio);
    console.log(pickup_locatio);
  }


const handleChangedesti = async(event)=>{
    setdesti_dt(1);
    const des = event.target.value;
    setquerydesti(des);
    const desti_location = await handleChange(event.target.value);
    setdesti_auto(desti_location);

}


const desti_location_select = async(data)=>{
   // console.log(data);
    setdesti_dt(0);
    setquerydesti(data.description);
    const get_pickup_lat_long = await get_location_lat_long(data.place_id);
    setdestination_location(get_pickup_lat_long);
    setTimeout(()=>{
         get_duration_distance_data(pickup_location,destination_location);
    },2000)
    
  }
  
  const pickup_location_select = async(data)=>{
   // console.log(data);
    setpickup_dt(0);
    setQuery(data.description);
    const get_pickup_lat_long = await get_location_lat_long(data.place_id);
    setpickup_location(get_pickup_lat_long);
   // get_duration_distance_data();
  }

  


  const handleChange = async (event) => {
    const value = event;
    var res = await apiService.get_google_autocomplete_data(value);   
    return res.data.predictions; 

    // try {
    //     const google_map_api = process.env.REACT_APP_GOOGLE_MAP_API
    //   //  console.log(google_map_api);
    //   const response = await axios.get(
    //     `/maps/api/place/autocomplete/json?input=${value}&key=${google_map_api}`
    //   );

    //  // console.log(response.data);

    //   return response.data.predictions;
    
    // } catch (error) {
    //   console.error('Error fetching autocomplete predictions:', error);
    // }
  };


  const get_location_lat_long = async(place_id)=>{


    var resp = await apiService.get_place_lat_lng(place_id);
    return resp.data.result.geometry.location;

    // try {
    //     const google_map_api = process.env.REACT_APP_GOOGLE_MAP_API
    //   //  console.log(google_map_api);
    //   const response = await axios.get(
    //     `/maps/api/place/details/json?place_id=${place_id}&key=${google_map_api}`
    //   );

    //  // console.log(response.data);

    //   return response.data.result.geometry.location;
    
    // } catch (error) {
    //   console.error('Error fetching autocomplete predictions:', error);
    // }
  }



  const actual_car_booking = async()=>{
    if(query==""){
        toast.error("Please add pickup address");
    }else if(querydesti==""){
        toast.error("Please add destination address");
    }else{
        var data = {
            pickup_location:query,
            destination_location:querydesti,
            pickup_location_lat_lng:pickup_location,
            destination_location_lat_lng:destination_location,
            distance:distance,
            duration:duration,
            price:calculated_price,
            services_id:product_Details.services_id

        }
        const dt = await apiService.actual_order_add(data);
        const new_data = dt.data.status;

        if(new_data==1){
            close_book_popup();
            service_booking();
            setsuccess(1);
            setbooking_popup_modal(1);

        }
    }
  }

  return (
    <>
      <Innernavbar />
      <section className="our_servces">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-md-5">
              <h3>Top {product_Details && <>{product_Details.name}</>}</h3>
              <h6>{location}</h6>
            </div>
            <div className="col-md-7">
              <div className="d-flex">
                <div className="input-group mb-4 border rounded-pill p-1 searchBar">
                  <div className="input-group-prepend border-0">
                    <button
                      id="button-addon4"
                      type="button"
                      className="btn btn-link text-info"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <GoogleAutocomplete />
                </div>
                <div className="input-group mb-4 ms-3 border rounded-pill p-1 searchBar">
                  <div className="input-group-prepend border-0">
                    <button
                      id="button-addon4"
                      type="button"
                      className="btn btn-link text-info"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <input
                    type="search"
                    placeholder="Search Booking Services"
                    aria-describedby="button-addon4"
                    className="form-control bg-none border-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="repairMainarea details">
        <div className="container">
          <nav
            lang="sk"
            role="navigation"
            aria-label="Omrvinková navigácia"
            className="breadcrumb"
          >
            <ol>
              <li>
                <a href="">{location}</a>
              </li>
              <span aria-hidden="true" className="breadcrumb-separator">
                &gt;
              </span>
              <li>
                <a href="#">
                  {product_Details && <>{product_Details.name}</>} in {location}
                </a>
              </li>
              <span aria-hidden="true" className="breadcrumb-separator">
                &gt;
              </span>
              <li>
                <a href="#">
                  {product_Details && <>{product_Details.service_name}</>}
                </a>
              </li>
              {/* <span aria-hidden="true" className="breadcrumb-separator">&gt;</span> */}
              {/* <li>
          <strong>
            <a href="#" aria-current="page">23+ Listings</a>
          </strong>
        </li> */}
            </ol>
          </nav>
        </div>
      </div>

      {product_Details && (
        <>
          <div className="detailsMain">
            <div className="container">
              <div className="servicItem mb-3">
                <div className="row p-3">
                  <div className="col-md-12">
                    <div className="flex-grow-1">
                      <h5>
                        {product_Details.service_name}
                        <div className="redirbtn">
                          <a href="#">{product_Details.name}</a>
                        </div>
                      </h5>
                      <div className="serviceInfoBx">
                        <div className="servicItemLft">
                          <div className="ratingproc">
                            <span className="rtno">
                              {product_Details.ratings_average}
                            </span>
                            <div className="starts">
                              <RatingsComponent
                                value={product_Details.ratings_average}
                              />
                            </div>
                            {product_Details.rating_count} Rating
                            {product_Details.rating_count == 0 ? <></> : <>s</>}
                          </div>
                          <div className="locationtext">
                            <i className="fa-solid fa-location-dot me-2"></i>
                            {product_Details.service_address}
                          </div>

                          <div className="btnGp">
                            <button type="button" className="btn btn-success">
                              <i className="fa-solid fa-phone me-2"></i>{" "}
                              <span className="mobile_hide">{product_Details.contact_no}</span>
                            </button>
                            <button type="button" className="btn btn-border">
                              <i className="fa-brands fa-whatsapp"></i><span className="mobile_hide"> Chat</span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-border"
                              onClick={() => {
                                add_review();
                              }}
                            >
                              <i className="fa-solid fa-star me-2"></i> <span className="mobile_hide">Tap to
                              Rate</span>
                            </button>
                            <button type="button" className="btn btn-border">
                              <img src="/assets/images/addphoto.png" alt="" />{" "}
                              <span className="mobile_hide">Add Photo</span>
                            </button>
                            <button type="button" className="btn btn-border">
                              <img src="/assets/images/share-blue.png" alt="" />{" "}
                              <span className="mobile_hide">Share</span>
                            </button>
                            {/* <button type="button" className="btn btn-border"><i className="fa fa-save	text-primary"> </i> Save</button> */}
                            <button type="button" className="btn btn-border">
                              <i
                                className={`fa-regular fa-heart ms-auto ${
                                  savedFavourites &&
                                  savedFavourites.includes(
                                    product_Details.services_id
                                  )
                                    ? "heart-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  add_favourite(product_Details);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div
                          className="servicItemRght"
                          style={{ display: "block" }}
                        >
                          <button
                            style={{ margin: "5px 0" }}
                            className="extra_filled_button"
                            onClick={() => {
                              service_booking();
                            }}
                          >
                            Enquire Now
                            {/* <div className="vendorinfo"> Get free details instantly via SMS </div> */}
                          </button>

                          <button
                            style={{ margin: "5px 0" }}
                            className="extra_filled_button"
                            onClick={() => {
                              book_now();
                            }}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reviwArea">
                <div className="row">
                  <div className="col-md-8">
                    <div className="reviewLeft">
                      <div className="photoUplArea inPAd">
                        <h4>Photos</h4>
                        {/* <ul>
                                                    <li>
                                                        <a href="#!">
                                                            <figure>
                                                                <img src="/assets/images/snap2.png" alt="" />
                                                                <figcaption>All <span> 40 Photos / Videos</span></figcaption>
                                                            </figure>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#!">
                                                            <figure>
                                                                <img src="/assets/images/video.png" alt="" />
                                                                <figcaption>Video <span> 10 Videos</span></figcaption>
                                                            </figure>
                                                        </a>
                                                    </li>
                                                </ul> */}
                        <button className="uploadBtn ">
                          <img
                            className="me-2"
                            src="/assets/images/upload.png "
                            alt=""
                          />
                          Upload Photos
                        </button>
                      </div>
                      <div className="quickInfoArea inPAd">
                        <div className="row">
                          <div className="col-md-8 quickInfLT">
                            <h4>Quick Information</h4>
                            <ul>
                              <li>
                                Mode Of Payment <span>Cash, BHIM, UPI</span>
                              </li>
                              <li>
                                Year of Establishment <span>2015</span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4 quickInfRT">
                            <h4>Timings</h4>
                            <p>
                              MON - SUN <span> 9.30am - 9.00pm</span>
                            </p>
                            <a href="#">
                              {" "}
                              <img src="/assets/images/penic.png" alt="" />{" "}
                              Suggest new hours
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="quickAnser inPAd">
                        <h4>Quick Information</h4>
                        <p>Would you like to ask a question ?</p>
                        <button className="postansbtn">
                          Post Your Question
                        </button>
                      </div>
                      <div className="revRatings inPAd">
                        <h4>Reviews & Ratings</h4>
                        <div className="row">
                          <div className="col-md-7">
                            <div className="rating_slide_all">
                              <div className=" total_rating_wpr">
                                <div className="green_box">
                                  {product_Details.ratings_average}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5>
                                    {product_Details.rating_count} Ratings
                                  </h5>
                                  <p>
                                    Reliep rating index based on{" "}
                                    {product_Details.rating_count} ratings
                                    across the web
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="startRAting">
                              <h6>Start your Review</h6>
                              <div className="starts">
                                <span
                                  onClick={() => {
                                    add_review();
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                </span>
                                <span
                                  onClick={() => {
                                    add_review();
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                </span>
                                <span
                                  onClick={() => {
                                    add_review();
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                </span>
                                <span
                                  onClick={() => {
                                    add_review();
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                </span>
                                <span
                                  onClick={() => {
                                    add_review();
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rtsend">
                          <p>Recent rating trend</p>
                          <ul>
                            <li>
                              <span>
                                5.0 <i className="fa-solid fa-star ms-2"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="urReview">
                          <p>User Reviews</p>
                          <div className="usertab_wpp">
                            <ul>
                              <li>
                                <span>Relevant</span>
                              </li>
                              <li>
                                <span>Latest</span>
                              </li>
                              <li>
                                <span>High to Low</span>
                              </li>
                            </ul>
                            <div className="usertab_search">
                              <span className=" usertab_search_icon"></span>
                              <input
                                type="text"
                                name=""
                                placeholder="Search reviews"
                                className=" usertab_search_input"
                                value=""
                              />
                            </div>
                          </div>
                        </div>

                        {review_ratings &&
                          review_ratings.map((rr, index) => {
                            return (
                              <>
                                <div className="urPost">
                                  <div className="urPostprofile">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        {rr.profile_pic == "" ? (
                                          <>
                                            <i
                                              className="fa fa-user"
                                              style={{
                                                fontSize: "30px",
                                                color: "blue",
                                              }}
                                            ></i>
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={rr.profile_pic}
                                              alt="..."
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6>
                                          {rr.first_name} {rr.last_name}{" "}
                                          <span></span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="date">
                                      {moment(rr.created_at).format(
                                        "MMMM Do YYYY"
                                      )}
                                    </div>
                                  </div>
                                  <div className="starts">
                                    <RatingsComponent value={rr.ratings} />
                                  </div>
                                  <p>{rr.comments}</p>
                                  <div className="reviewsocial__left">
                                    <ul>
                                      <li>
                                        <a href="#!">
                                          <i className="fa-regular fa-thumbs-up me-2"></i>
                                          Helpful
                                        </a>{" "}
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <i className="fa-brands fa-rocketchat me-2"></i>
                                          Comment
                                        </a>{" "}
                                      </li>
                                      <li>
                                        <a href="#!">
                                          <i className="fa-solid fa-share me-2"></i>
                                          Share
                                        </a>{" "}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>

                      <div className="cmsTEXTtype inPAd">
                        <h3>About US</h3>
                        <p>{product_Details.basic_info}</p>
                      </div>
                      <div className="cmsTEXTtype inPAd">
                        {/* <h3>Most Searched</h3> */}
                        <p>{product_Details.other_info}</p>
                      </div>
                    
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="addbox"></div>
                    <div className="addbox"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Popup */}

          <div
            className={`modal fade ${review_show == 1 ? "mdl" : ""}`}
            id="myModal"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Modal Header</h4>
        </div> */}
                <div className="modal-body">
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={product_Details.featured_image}
                      style={{
                        width: "170px",
                        width: "170px",
                        borderRadius: "50%",
                      }}
                    />
                    <h3 style={{ marginTop: "15px" }}>
                      {product_Details.service_name}
                    </h3>
                    <h5 style={{ marginTop: "15px" }}>
                      {moment().format("dddd MMMM DD YYYY")}
                    </h5>
                    <div style={{ marginTop: "20px" }}>
                      <div class="star-rating">
                        <input
                          type="radio"
                          id="star5"
                          name="rating"
                          value="5"
                          onChange={(e) => {
                            set_review_ratings_add(e.target.value);
                          }}
                        />
                        <label for="star5"></label>
                        <input
                          type="radio"
                          id="star4"
                          name="rating"
                          value="4"
                          onChange={(e) => {
                            set_review_ratings_add(e.target.value);
                          }}
                        />
                        <label for="star4"></label>
                        <input
                          type="radio"
                          id="star3"
                          name="rating"
                          value="3"
                          onChange={(e) => {
                            set_review_ratings_add(e.target.value);
                          }}
                        />
                        <label for="star3"></label>
                        <input
                          type="radio"
                          id="star2"
                          name="rating"
                          value="2"
                          onChange={(e) => {
                            set_review_ratings_add(e.target.value);
                          }}
                        />
                        <label for="star2"></label>
                        <input
                          type="radio"
                          id="star1"
                          name="rating"
                          value="1"
                          onChange={(e) => {
                            set_review_ratings_add(e.target.value);
                          }}
                        />
                        <label for="star1"></label>
                      </div>
                    </div>

                    <div>
                      <input
                        type="textarea"
                        onChange={(e) => {
                          set_review_comments(e.target.value);
                        }}
                        className="form-control"
                        style={{ height: "100px", marginTop: "20px" }}
                        placeholder="Enter your comment here"
                      />
                    </div>

                    <button
                      className="btn btn-primary mt-4"
                      onClick={() => {
                        click_submit_review();
                      }}
                    >
                      Submit Review
                    </button>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      close_review_popup();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={review_show == 1 ? "bg-opacity" : ""}></div>

          {/* enquire popup */}

          <div
            className={`modal fade ${enquire_show_hide == 1 ? "mdl" : ""}`}
            id="myModal"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Modal Header</h4>
        </div> */}
                <div className="modal-body">
                  {(() => {
                    if (success == 1) {
                      return (
                        <>
                          <div className="success">
                            {/* <img src="/assets/images/success.json" /> */}
                            <lottie-player
                              className="suc"
                              src="/assets/images/success.json"
                              background="transparent"
                              speed="1"
                              style={{
                                width: "300px",
                                height: "300px",
                                margin: "0 auto",
                              }}
                              loop
                              autoplay
                            ></lottie-player>
                            <h1>{booking_popup_modal==1?"Booking":"Enquiry"} successfully added</h1>
                            <p>
                              Your {booking_popup_modal==1?"booking":"enquiry"} details is successfully received us.
                              We'll Contact you very soon.
                            </p>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div>
                            <div className="order_request">
                              <h4>Get the List of Top</h4>
                              <h4>
                                {product_Details && (
                                  <>{product_Details.service_name}</>
                                )}
                              </h4>
                            </div>
                            <div className="order_input">
                              <div className="input-container">
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  required
                                  onChange={(e) => {
                                    setorder_name(e.target.value);
                                  }}
                                />
                                <label for="name">Name</label>
                              </div>

                              <div className="input-container">
                                <input
                                  type="tel"
                                  id="mobile"
                                  name="mobile"
                                  required
                                  onChange={(e) => {
                                    setorder_mobile(e.target.value);
                                  }}
                                />
                                <label for="mobile">Mobile Number</label>
                              </div>

                              <button
                                className="send_booking"
                                onClick={() => {
                                  send_booking();
                                }}
                              >
                                SEND BOOKING
                              </button>

                              <ul class="booking_info">
                                <li>
                                  Your requirement is sent to the selected
                                  relevant businesses
                                </li>
                                <li>
                                  Businesses compete with each other to get you
                                  the Best Deal
                                </li>
                                <li>You choose whichever suits you best</li>
                                <li>Contact info sent to you by SMS/Email</li>
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      close_enquire_popup();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={enquire_show_hide == 1 ? "bg-opacity" : ""}></div>

          {/* Book now section with MAP */}

          <div
            className={`modal fade ${book_now_show == 1 ? "mdl" : ""}`}
            id="myModal"
            role="dialog"
            style={{ "--bs-modal-width": "80%", marginTop: "1%" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <h3>Location</h3>
                          <div>
                            <div className="order_input">
                              <div className="input-container">
                                
                                     <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  required
                                  value={query} onChange={handleChangepickup}
                                />
                               
                               
                                <label for="name">PickUp Location</label>
                              </div>

{pickup_dt==1?
<ul className="autocomplete-results" style={{"maxWidth":"30%"}}>
        {pickup_auto.map((result) => (
          <li key={result.place_id} onClick={()=>{pickup_location_select(result)}}>{result.description}</li>
        ))}
      </ul>
:""}
                              

                              <div className="input-container">
                                <input
                                  type="text"
                                  id="mobile"
                                  name="mobile"
                                  required
                                  value={querydesti} onChange={handleChangedesti}
                                />
                                <label for="mobile">Destination Location</label>
                                {desti_dt==1?
                                <ul className="autocomplete-results">
        {desti_auto.map((result) => (
          <li key={result.place_id} onClick={()=>{desti_location_select(result)}}>{result.description}</li>
        ))}
      </ul>
      :""}
                              </div>

                          {distance?(<h5>Distance: {distance}</h5>):""}
                              {duration?(<h5>Duration: {duration}</h5>):""}
                              {calculated_price?(<h5>Price: Rs.{calculated_price}</h5>):""}
                              
                             <br />
                           

                              <button
                                className="send_booking"
                                onClick={() => {
                                  actual_car_booking();
                                }}
                              >
                                ADD BOOKING
                              </button>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div>
                        <MapContainer from={pickup_location} to={destination_location} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      close_book_popup();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={book_now_show == 1 ? "bg-opacity" : ""}></div>
        </>
      )}

      <Footer />
    </>
  );
}
export default Details;
