import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import Navbar from "../Components/Navbar";

function Privacy_policy(){
    return(
        <>


<Helmet>
        <link rel="stylesheet" href="/assets/css/about.css" />
      </Helmet>

<Navbar />


    <section class="container max-w-7xl  mx-auto py-16 px-4 flex">
    <div class="container privacy">
    <header>
      <h1>Privacy Policy</h1>
    </header>
    <section>
      <h2>1. Introduction</h2>
      <p>Welcome to our privacy policy. Your privacy is critically important to us. This policy outlines how we collect, use, and protect your personal information.</p>
    </section>
    <section>
      <h2>2. Information We Collect</h2>
      <p>We collect personal information such as your name, email address, shipping address, and payment details when you make a purchase or create an account on our website.</p>
    </section>
    <section>
      <h2>3. How We Use Your Information</h2>
      <p>We use your information to process orders, communicate with you about your order, and provide customer support. We may also use your information for marketing purposes, such as sending promotional emails.</p>
    </section>
    <section>
      <h2>4. Information Sharing</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill our service obligations (e.g., shipping partners).</p>
    </section>
    <section>
      <h2>5. Security of Your Information</h2>
      <p>We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
    </section>
    <section>
      <h2>6. Your Rights</h2>
      <p>You have the right to access, update, or delete your personal information. You may also opt-out of receiving marketing communications from us.</p>
    </section>
    <footer>
      <p>This privacy policy was last updated on <span id="lastUpdated">24-06-2024</span>.</p>
      <p>Contact us at <a href="mailto:info@reliep.com">info@reliep.com</a> for any questions or concerns regarding your privacy.</p>
    </footer>
  </div>
      </section>



 <Footer />



        
        </>
    )
}

export default Privacy_policy;