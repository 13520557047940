import { Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import {Helmet} from 'react-helmet';
import { useEffect, useState } from "react";
import apiService from "../apiService";
import Navbar from '../Components/Navbar';
function Becomepartner(){

    const [isFormOpen, setIsFormOpen] = useState(false);

    // Open the form when a plan is clicked
    const handlePlanClick = () => {
        setIsFormOpen(true);
    };

    // Close the form
    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Collect form data and submit to server or handle as needed
        console.log('Form submitted');
        handleCloseForm(); // Close form after submit
    };
return(
    <>
 <Helmet>
                <link rel="stylesheet" href="/assets/css/categoryservice.css" />
            </Helmet>
            <Navbar />

            <div className="flex flex-col md:flex-row max-w-7xl mx-auto mt-20 md:mt-4">
                <div className="w-full md:w-5/6 p-4">
                    <h2 className="text-2xl font-semibold mb-6">Our Plans</h2>
                    <div className="flex flex-wrap gap-6">
                        <div
                            className="p-4 border rounded-lg shadow-lg cursor-pointer w-full sm:w-1/2 md:w-1/3"
                            onClick={handlePlanClick}
                        >
                            <h3 className="text-xl font-semibold">Basic Plan</h3>
                            <p className="mt-2">Some details about the plan.</p>
                        </div>
                        <div
                            className="p-4 border rounded-lg shadow-lg cursor-pointer w-full sm:w-1/2 md:w-1/3"
                            onClick={handlePlanClick}
                        >
                            <h3 className="text-xl font-semibold">Premium Plan</h3>
                            <p className="mt-2">Some details about the plan.</p>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/6 p-4 flex flex-col gap-4">
                    <h3>Become a Partner</h3>
                    <p>Learn more about how you can join our platform.</p>pm run dev
                </div>
            </div>

            {isFormOpen && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-xl font-semibold mb-4">Partner Signup Form</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="businessName" className="block">Business Name</label>
                                <input
                                    type="text"
                                    id="businessName"
                                    name="businessName"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="businessLocation" className="block">Business Location</label>
                                <input
                                    type="text"
                                    id="businessLocation"
                                    name="businessLocation"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="phone" className="block">Phone Number</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="pincode" className="block">Pincode</label>
                                <input
                                    type="text"
                                    id="pincode"
                                    name="pincode"
                                    className="w-full p-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="flex justify-between">
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg">Submit</button>
                                <button
                                    type="button"
                                    onClick={handleCloseForm}
                                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                >Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
    </>
)
}
export default Becomepartner;