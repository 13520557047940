// GoogleAutocomplete.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apiService from '../apiService';

const GoogleAutocomplete = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [location, set_location] = useState("");
  const [data_view, set_data_view] = useState(0);

  useEffect(()=>{
  const p_city = localStorage.getItem("present-city");
  set_location(p_city);
  },[])


  const handleChange = async (event) => {
    const value = event.target.value;
    setQuery(value);

    try {
    //     const google_map_api = process.env.REACT_APP_GOOGLE_MAP_API
    //    // console.log(google_map_api);
    //   const response = await axios.get(
    //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=${google_map_api}`
    //   );

    var res = await apiService.get_google_autocomplete_data(value);

      //console.log(res.data);

      setResults(res.data.predictions);
      set_data_view(1);
    } catch (error) {
      console.error('Error fetching autocomplete predictions:', error);
    }
  };


  const location_selected = (data)=>{
  //  console.log(data.structured_formatting.main_text);
    localStorage.setItem("present-city",data.description)
    var lo = localStorage.getItem("present-city");
    set_location(lo);
    set_data_view(0);
    window.location.reload();
  }

  return (
    <div className="autocomplete">
      <input type="search" placeholder={location} aria-describedby="button-addon4" className="form-control bg-none border-0" value={query} onChange={handleChange} />
     {(()=>{
        if(data_view==1){
            return(
                <>
                 <ul className="autocomplete-results">
        {results.map((result) => (
          <li key={result.place_id} onClick={()=>{location_selected(result)}}>{result.description}</li>
        ))}
      </ul>
                </>
            )
        }
     })()}
     
    </div>
  );
};

export default GoogleAutocomplete;
