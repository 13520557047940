import axios from 'axios';

const u_token = localStorage.getItem("user-token");



const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Adjust the base URL to match your Laravel API's URL
    withCredentials: true, // This is necessary for sending cookies (e.g., for session authentication)
});

let authHeader = {
    headers: {
        Authorization: "Bearer $2y$12$SVQEflgKZtSUeOrRvzQkzudZG9Qre7BkoE7biVHgnrznc4BxigpES",
    },
  };


  let apiHeader = {
    headers:{
        Authorization:"Bearer "+u_token
    }
  }
console.log(process.env.REACT_APP_ACCESS_TOKEN);
const apiService = {
    generateOtp(email) {
        return apiClient.post('/generate-otp', { email });
    },
    verifyOtp(email, otp) {
        return apiClient.post('/verify-otp', { email, otp });
    },
    logout() {
        return apiClient.post('/logout');
    },
    getUser() {
        return apiClient.get('/user');
    },

    getcategories(){
        return apiClient.get("/categories/get",authHeader);
    },

    get_services_data(){
        return apiClient.get("/services",authHeader);
    },


    get_user_profile_data(){
        return apiClient.get("/get-user-profile-data",apiHeader);
    },

    user_details_add(formData){
        return apiClient.post("/user-details-add",formData,apiHeader)
    },

    get_user_full_details(){
        return apiClient.get("/get-user-full-details",apiHeader);
    },

    user_details_update(formData){
        return apiClient.post("/user-details-update",formData,apiHeader)
    },

    get_notifications(){
        return apiClient.get("/get-notifications",apiHeader);
    },
    notification_seen(data){
        return apiClient.get("/notification-seen/"+data,apiHeader);
    },
    invite_user(data){
        return apiClient.get("/invite-user/"+data, apiHeader);
    },
    get_filtered_service_data(slug,location){
        var ary = {"slug":slug,"location":location}
        return apiClient.post("/get-filtered-service-data",ary,authHeader);
    },
    save_to_favourite(service_id,token){
        return apiClient.get("/save-to-favourite/"+service_id,apiHeader);
    },
    get_favourite_saved_services(){
        return apiClient.get("/get-favourite-saved-services",apiHeader);
    },
    get_specific_product_data(slug){
        return apiClient.get("/get-specific-product-data/"+slug,authHeader);
    },
    get_reviews(slug){
        return apiClient.get("/get-reviews/"+slug,authHeader);
    },
    submit_review_data(data){
        return apiClient.post('/submit-review-data',data,apiHeader);
    },
    get_your_favourite_service(){
        return apiClient.get("/get-your-favourite-service",apiHeader);
    },

    enquiry_order_add(data){
        return apiClient.post("/enquiry-order-data-add",data,authHeader)
    }, 
    actual_order_add(data){
        return apiClient.post("/order-data-submit",data,authHeader)
    },

    get_google_autocomplete_data(data){
        return apiClient.get("/google-autocomplete-api/"+data,authHeader)
    },
    get_place_lat_lng(data){
        return apiClient.get("/get-place-lat-lng/"+data, authHeader)
    },
    get_location_matrix_data(origin,destination){
        return apiClient.get("/get_location_matrix_data/"+origin+"/"+destination, authHeader)
    },
    get_user_history_data(){
        return apiClient.get("get_user_history_data",apiHeader);
    },

    feedback_submit(data){
        return apiClient.post("web-feedback-submit",data,apiHeader);
    },

    get_category_wise_data(slug){
        return apiClient.get("get-category-wise-data/"+slug,apiHeader);
    },
    get_cat_service_data(data){
        return apiClient.post("get-filtered-service-data-web",data,apiHeader);
    },
    get_partner_data(data){
        return apiClient.post("get-partner-data",data,apiHeader);
    },

    get_cat_with_cat(){
        return apiClient.get("get-category-within-category",apiHeader);
    }


};

export default apiService;
