import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import {Helmet} from 'react-helmet';
import Navbar from '../Components/Navbar';
function About(){
return(
    <>
            <Helmet>
        <link rel="stylesheet" href="/assets/css/about.css" />
      </Helmet>

<Navbar />


    <section class="container max-w-7xl  mx-auto py-16 px-4 flex">

        <div class="flex flex-col-reverse md:flex-row items-center justify-between">
          
    
          <div class="md:w-1/2 space-y-6">
            <h2 class="text-4xl font-bold">About Reliep</h2>

      
            <div class="space-y-4">
              <p class="text-[#000000] leading-relaxed">
                Reliep is your one-stop platform for all your everyday service needs. From home repairs and maintenance to fitness, healthcare, and even garment shopping, we offer a wide range of services designed to make your life easier. Whether you're looking to book a professional for home cleaning, schedule a healthcare appointment, or pay your utility bills, Reliep brings convenience to your fingertips.
              </p>
              <p class="text-[#000000] leading-relaxed">
                With a user-friendly interface and a network of verified service providers, we ensure you get fast, reliable, and quality service every time. Our mission is to simplify your daily tasks, allowing you to focus on what really matters. At Reliep, we’re not just offering services—we’re delivering peace of mind.

              </p>
              <p class="text-[#000000] leading-relaxed">
                Experience the ease of seamless service booking and let us handle the rest.
              </p>
            </div>
   
            <button class="mt-4 px-10 py-3 bg-[#850C85] text-white rounded-lg shadow-md ">
              Explore Services
            </button>
          </div>
      

          <div class="md:w-1/2 flex justify-center md:justify-end pt-10 md:pt-0 mb-8 md:mb-0">
            <img src="/about/images/about-reliep.png" alt="About Image" class="w-3/4 h-auto rounded-lg shadow-lg" />
          </div>
        </div>
      </section>



      <section class="bg-gradient-to-r from-[#240A24] to-[#4F204F] pt-16 pb-16 md:pb-0 px-4">

        <div class="container mx-auto flex flex-col md:flex-row items-center md:items-start max-w-7xl md:h-[550px]">
          
     
          <div class="md:w-[40%] flex justify-center md:justify-start md:self-end mb-8 md:mb-0">
            <img src="/about/images/reliep-founder.png" alt="Founder Image" class="w-3/4 md:w-full  object-cover" />
          </div>
      
     
          <div class="md:w-[60%] text-white space-y-6 flex flex-col justify-center">
            <div class="mb-4 md:mb-8 flex flex-col gap-4">
                <p class="text-lg font-semibold">About the Founder</p>
                <h2 class="text-4xl font-bold ">- Mr. Rakesh Bali</h2>
            </div>

            <p class="text-white">
              Rakesh Bali, the founder of Reliep, is an innovative entrepreneur with a mission to simplify daily tasks through technology. With extensive experience in the service industry, Rakesh saw the need for a platform that connects users to reliable, on-demand services across various categories, from home repairs to healthcare.
            </p>
            <p class="text-white">
              His passion for customer satisfaction and seamless experiences has driven Reliep’s success. Under his leadership, Reliep has become a trusted solution for users seeking convenience and quality. Rakesh remains dedicated to expanding the platform’s reach, ensuring that users and service providers benefit from an easy and efficient process.
            </p>
          </div>
        </div>
      </section>


      <section class="bg-[#FFF0FF] py-16 px-4">

        <div class="container mx-auto max-w-7xl text-center">
          

          <h2 class="text-4xl font-bold text-[#000000] mb-4">Benefits of Choosing Us</h2>
          

           <div class="max-w-xl mx-auto text-center">
            <p class="text-[#000000] mb-12 ">
                At Reliep, we are committed to providing a service experience that stands out. Here’s why you should choose us:
              </p>
           </div>

          
  
          <div class="grid gap-8 md:grid-cols-3">
            

            <div class="bg-white rounded-xl px-6 py-10 shadow-lg flex flex-col items-center justify-center">
                <img src="/about/images/quality.png" alt="Benefit 3" class="w-20 h-20  rounded-t-xl mb-4" />
                <h3 class="text-xl font-bold text-[#000000] mb-2">Quality Service</h3>
                <p class="text-[#000000]">Our verified professionals ensure reliable, high-quality service across all categories, from home repairs to healthcare.</p>
              </div>

            <div class="bg-white rounded-xl px-6 py-10 shadow-lg flex flex-col items-center justify-center">
                <img src="/about/images/genuine-product.png" alt="Benefit 3" class="w-20 h-20  rounded-t-xl mb-4" />
                <h3 class="text-xl font-bold text-[#000000] mb-2">Genuine Products</h3>
                <p class="text-[#000000]">We use only authentic, premium products to guarantee lasting results and the best possible service outcomes.</p>
              </div>
            
 
            <div class="bg-white rounded-xl px-6 py-10 shadow-lg flex flex-col items-center justify-center">
              <img src="/about/images/value-for-money.png" alt="Benefit 3" class="w-20 h-20  rounded-t-xl mb-4" />
              <h3 class="text-xl font-bold text-[#000000] mb-2">Value For Money</h3>
              <p class="text-[#000000]">Enjoy top-tier services at competitive prices, ensuring you get excellent quality without overspending.</p>
            </div>
            
          </div>
        </div>
      </section>
      {/* <footer className="bg-gray-800 text-white py-10">
        <div className="max-w-7xl mx-auto px-4">
            <p className="text-center">
                &copy; 2024 Your Company. All rights reserved.
            </p>
            <p style={{"textAlign":"center"}}><a href="/privacy-policy">Privacy policy</a> &nbsp;&nbsp;&nbsp;&nbsp; <a href="/terms-conditions">Terms Condition</a></p>
        </div>
    </footer> */}

 <Footer />
    </>
)
}
export default About;