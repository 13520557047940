import { Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import {Helmet} from 'react-helmet';
import { useEffect, useState } from "react";
import apiService from "../apiService";
import Navbar from '../Components/Navbar';

function Subcategories(){

    const { slug } = useParams();
    const [nslug, setslugdata] = useState("");
    const [categories_data, setcategories_data] = useState([]);

    useEffect(()=>{
        get_category_data(slug)
    },[])

    const get_category_data = async(slug)=>{
        const res_data = await apiService.get_category_wise_data(slug);
        var mcat = res_data["data"];
        var nnslg = mcat["main-category"];
       // console.log(nnslg.name);
       setslugdata(nnslg.name);
       setcategories_data(mcat["categories"]);
    }

    return(
        <>
                   <Helmet>
        <link rel="stylesheet" href="/assets/css/service.css" />
      </Helmet>
<Navbar />

        <section class="py-20 bg-white">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-12">{nslug}</h2>

                
                {categories_data.length > 0 ? (
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
    {categories_data.map((dt, key) => (
        <a key={key} href={`/category/services/${dt.slug}`}>
            <div
                className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative desk"
                style={{ backgroundImage: `url(${dt.icon})` }}
            >
                <div className="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                    <span className="text-white text-xl font-semibold">
                        {dt.name}
                    </span><br />
                    <span className="text-white text-xl font-semibold">
                        Starts &#8377;{dt.price}
                    </span>
                </div>
            </div>





            <div className="mobl">
                <img src={dt.icon} />
            <div className="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                    <span className="text-white text-xl font-semibold md:text-black text-black">
                        {dt.name}
                    </span><br />
                    <span className="text-white text-xl font-semibold md:text-black text-black">
                        Starts &#8377;{dt.price}
                    </span>
                </div>
            </div>



        </a>
    ))}
</div>
) : (
    <div className="flex flex-col items-center justify-center h-80 bg-gray-100 rounded-xl">
   <img src="/assets/images/out-of-stock.png" />
        <h2 className="text-gray-600 text-lg font-semibold">
            No Categories Available
        </h2>
        <p className="text-gray-500 text-sm text-center">
            Check back later for updates or explore other sections of the site.
        </p>
    </div>
)}
            </div>
        </section>

        <Footer />

        </>
    )
}

export default Subcategories;