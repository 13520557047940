import { useEffect, useState } from "react"
import Sectionheader from "./Sectionheader"
import apiService from "../../apiService"
import { Link } from "react-router-dom";

function Favourites() {

    const [favourites, set_favourites] = useState([]);

useEffect(()=>{
    get_your_favourite_service();
},[])

const get_your_favourite_service = async()=>{
    const res = await apiService.get_your_favourite_service();

    if(res.data.status==1){
        set_favourites(res.data.data);
    }
}

    return (
        <>
            <div className="col-md-8 ">
                <div className="profile_content">
                    <Sectionheader />
                    <div>
                        <h2 className="text-center m-4">Your favourite services</h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {favourites && favourites.map((fav,index)=>{
                                    return(<>
                                    <tr>
                                    <td><img src={fav.featured_image} alt="Product 1" style={{ width: '100px' }} /></td>
                                    <td>{fav.service_name}</td>
                                    <td><Link to={`/details/${fav.slug}`} className="btn btn-primary" target="_blank">View Service</Link></td>
                                </tr>
                                    </>)
                                })}
                                
                                
                                {/* Add more rows for additional products */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Favourites