import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpVerifation from './Pages/OtpVerifation';
import Profile from './Pages/Profile';
import ProtectedRoute from './util/ProtectedRoute';
import AuthRoute from './util/AuthRoute';
import Services from './Pages/Services';
import Invite from './Pages/Invite';
import Service from './Pages/Service';
import Details from './Pages/Details';
import About from './Pages/About';
import Terms from './Pages/terms';
import Privacy_policy from './Pages/privacy-policy';
import Contact from './Pages/Contact';
import RefundPolicy from './Pages/refund-policy';
import PartnerStore from './Pages/partner-store';
import Categories from './Pages/Categories';
import Subcategories from './Pages/subcategory';
import Becomepartner from './Pages/become-partner';

import Categoryservices from './Pages/category-services';

function App() {
  return (
    <>
      <ToastContainer />
      <Router basename='/'>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/login" element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          } />

          <Route path="/otp-verification" element={
            <AuthRoute>
              <OtpVerifation />
            </AuthRoute>
          } />

          <Route path='/profile' element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />

        <Route path='/services' element={
           
              <Services />
           
          } />

     <Route path='/about-us' element={
           
           <About />
        
       } />

       <Route path="/partner/store/:emp_id" element={
          <PartnerStore />
       } />

<Route path='/terms-conditions' element={
           
           <Terms />
        
       } />

<Route path='/privacy-policy' element={
           
           <Privacy_policy />
        
       } />

<Route path='/refund-policy' element={
           
           <RefundPolicy />
        
       } />

<Route path='/contact-us' element={
           
           <Contact />
        
       } />

      <Route path='/invite/:link' element={
           
           <Invite />
        
       } />
       <Route path='/service/:slug' element={
      <Service />
       } />
       <Route path='/details/:slug' element={
      <Details />
       } />

<Route path='/categories/:slug' element={
      <Categories />
       } />

<Route path='/sub-category/:slug' element={
      <Subcategories />
       } />

<Route path='/category/services/:slug' element={
      <Categoryservices />
       } />

<Route path='/become-a-partner' element={
      <Becomepartner />
       } />





        </Routes>
      </Router>
    </>
  );
}

export default App;
