import { useEffect, useState } from "react";
import apiService from "../../apiService";
function Sectionheader(){
    const [ProfileImage,setProfileImage] = useState("assets/images/Ellipse_user.svg");
    const [u_name, set_u_name] = useState("John Doe");
    const [u_phone,set_u_phone] = useState("7364011112");


    useEffect(()=>{
        get_user_profile_data()
        
        },[])

    const get_user_profile_data = async()=>{
        const data = await apiService.get_user_profile_data()
        if(data.status==200){
            
            if(data.data.status==1){
                set_u_name(data.data.u_data["name"]);
                set_u_phone(data.data.u_data["phone"]);
                if(data.data.u_data["profile_pic"]!=""){
                    setProfileImage(data.data.u_data["profile_pic"]);
                }
            }
        }
    }


    return(
        <>
                                    <div className="profile_contentTop">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                       
                                    </div>
                                    <div className="avatar-preview">
                                        <div
                                            id="imagePreview"
                                            style={{
                                                backgroundImage: `url(${ProfileImage})`,
                                            }}
                                        ></div>
                                    </div>
                                    <h5>{u_name}</h5>
                                </div>
                            </div>
        </>
    )
}
export default Sectionheader;