import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');

        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }

    }


    const[show_hide, set_show_hide] = useState("");
  const menu_show_hide = ()=>{
    console.log("Mobile button click");
    set_show_hide("n_mobile_show");
  }
  const gray_body_click = ()=>{
    set_show_hide("");
  }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);


    const mobile_menu = ()=>{
        setMenuVisible(!menuVisible);
    }

    return (
        <>
        {/* <div className="navbar-area">
            <div className="mobile-nav">
                <a href="/" className="logo">
                    <img className="img-fluid" src="/assets/images/Reliep.svg" alt="" />
                </a>
                <Link to="/profile" className="common-button" style={{
                    position: 'absolute',
                    right: '15px',
                    top: '11px',
                    zIndex: 999,
                }}>
                    {isLoggedIn ? 'Profile' : 'Login Now'}
                </Link>
            </div>
            <div className="main-nav">
                <div className="header-top text-end">
                    <div className="container">
                        
                        <ul>
                        <li onClick={()=>{menu_show_hide()}} className='mobile_only'><i class="fa fa-bars" aria-hidden="true" style={{"fontSize": "22px"}}></i></li>
                            <li className='mobile_only'>
                            <a className="navbar-brand" href="/">
                                <img
                                    className="img-fluid"
                                    src="/assets/images/whiteReliep.png"
                                    alt=""
                                />
                            </a>
                            </li>
                            <li>
                                <Link to="#">
                                    <img src="/assets/images/smartphone-call.png" alt="" />
                                    App
                                </Link>
                            </li>
                           
                            <li>
                                <Link to="#">
                                    <img src="/assets/images/bussinesIcon.svg" alt="" />
                                    Businesses
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div  className={`header_bottom ${show_hide}`}>
                    <div className="container clst_cls">
                        <nav className="navbar navbar-expand-md navbar-light pb-1">
                            <a className="navbar-brand" href="/">
                                <img
                                    className="img-fluid"
                                    src="/assets/images/Reliep.svg"
                                    alt=""
                                />
                            </a>
                            <div
                                className="collapse navbar-collapse mean-menu"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <Link to="" className="nav-link">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/about-us" className="nav-link">
                                            About Us
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/services" className="nav-link">
                                            Service
                                        </Link>
                                    </li>
                                  
                                    <li className="nav-item">
                                        <Link to="" className="nav-link">
                                            Offers
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="" className="nav-link">
                                            <img src="/assets/images/search.svg" alt="" />
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="" className="nav-link">
                                            <img src="/assets/images/online-shopping.png" alt="" />
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/profile" className="nav-link">
                                            <img src="/assets/images/user.png" alt="" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div className={`body_gray ${show_hide}`} onClick={()=>{gray_body_click()}}></div> */}


<nav class="bg-white shadow-lg fixed top-0 left-0 right-0 z-50 md:relative md:z-auto">
            <div class="max-w-7xl mx-auto px-2 md:px-4">
                <div class="flex justify-between items-center">
          
                    <div class="flex space-x-4">
                        <a href="/" class="flex items-center py-5 px-2 text-gray-700">
                            <img src="/services-img/images/logo.png" alt="Logo" class="h-8 w-16 mr-2" />
                        </a>
                    </div>
    
             
                    <div class="hidden md:flex items-center space-x-1">
                        <a href="/" class="py-5 px-3 text-gray-700 hover:text-gray-900">Home</a>
                        <a href="/about-us" class="py-5 px-3 text-gray-700 hover:text-gray-900">About</a>
                        <a href="/services" class="py-5 px-3 text-gray-700 hover:text-gray-900">Services</a>
                        <a href="/blog" class="py-5 px-3 text-gray-700 hover:text-gray-900">Blog</a>
                        <a href="/contact-us" class="py-5 px-3 text-gray-700 hover:text-gray-900">Contact</a>
                        {/* <a href="/become-a-partner" class="py-5 px-3 text-gray-700 hover:text-gray-900">Become A Partner</a> */}
                        
                    </div>
    
         
                    <div class="md:hidden flex items-center" onClick={()=>mobile_menu()}>
                        <button class="mobile-menu-button">
                            <svg class="hamburger-icon w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                            <svg class="close-icon hidden w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
    

            <div className={`mobile-menu ${menuVisible ? 'block' : 'hidden'} md:hidden`}>
                <a href="/" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Home</a>
                <a href="/about-us" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">About</a>
                <a href="/services" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Services</a>
                <a href="/blog" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Blog</a>
                <a href="/contact-us" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200">Contact</a>
                {/* <a href="/become-a-partner" class="py-5 px-3 text-gray-700 hover:text-gray-900">Become A Partner</a> */}
            </div>
        </nav>

        </>
    );
}

export default Navbar;
